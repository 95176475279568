import * as Actions from '../constants/actionTypes';
import { RESPONSE_PAYMENTEZ_SEND_PAYCARD, RESPONSE_PAYMENTEZ_SEND_PAYDEBITCARD, RESPONSE_PAYMENTEZ_SEND_PAYCLICKTOPAY } from '../constants/endpoints';
import { getAuth } from '../auth/Auth';
import { push } from 'connected-react-router'
import { INFO_RESPONSE_PAYMENT } from '../constants/config';
import { changeCurrentTheme } from './themeActions';
/*eslint no-console: ["error", { allow: ["warn", "error"] }] */

export function changeServerData(response) {
  return { type: Actions.SERVER_ANSWER, value: response }
}

export const fetchPostServerPaymanetezStatus = (response, orderId) => {
  return fetch(`${RESPONSE_PAYMENTEZ_SEND_PAYCARD}${orderId}/`, {
    method: "POST",
    body: JSON.stringify(response),
    headers: new Headers({
      'Auth-Token': getAuth(),
      'Content-Type': 'application/json'
    }),
    credentials: "same-origin"
  })
}

export const fetchPostServerPaymanetezDebitStatus = (response, orderId) => {
  return fetch(`${RESPONSE_PAYMENTEZ_SEND_PAYDEBITCARD}${orderId}/`, {
    method: "POST",
    body: JSON.stringify(response),
    headers: new Headers({
      'Auth-Token': getAuth(),
      'Content-Type': 'application/json'
    }),
    credentials: "same-origin"
  })
}

export const fetchPostServerPaymanetezClickToPayStatus = (response, orderId) => {
  return fetch(`${RESPONSE_PAYMENTEZ_SEND_PAYCLICKTOPAY}${orderId}/`, {
    method: "POST",
    body: JSON.stringify(response),
    headers: new Headers({
      'Auth-Token': getAuth(),
      'Content-Type': 'application/json'
    }),
    credentials: "same-origin"
  })
}

export const asyncFetchPostServerPaymanetezStatus = (response, orderId, orderData, extra_info) => {

  response.user = {
    email: orderData["user-email"] || extra_info.user.email,
    name: orderData["user-name"] || extra_info.user.name,
    last_name: orderData["user-last_name"] || extra_info.user.last_name,
  };

  return function (dispatch) {
    return fetchPostServerPaymanetezStatus(response, orderId)
      .then(response => response.json())
      .then((orderData) => {
        dispatch(changeServerData(orderData))
        return dispatch(push(INFO_RESPONSE_PAYMENT))
      })
      .catch(error => {
        console.error(error);
        return dispatch(changeServerData(error))
      });
  };
}

export const asyncFetchPostServerPaymanetezDebitStatus = (response, orderId, orderData, extra_info) => {

  response.user = {
    email: orderData["user-email"] || extra_info.user.email,
    name: orderData["user-name"] || extra_info.user.name,
    last_name: orderData["user-last_name"] || extra_info.user.last_name,
  };

  return function (dispatch) {
    return fetchPostServerPaymanetezDebitStatus(response, orderId)
      .then(response => response.json())
      .then((orderData) => {
        dispatch(changeServerData(orderData))
        return dispatch(push(INFO_RESPONSE_PAYMENT))
      })
      .catch(error => {
        console.error(error);
        return dispatch(changeServerData(error))
      });
  };
}

export const asyncFetchPostServerPaymanetezClickToPayStatus = (response, orderId, orderData, extra_info) => {

  response.user = {
    email: orderData["user-email"] || extra_info.user.email,
    name: orderData["user-name"] || extra_info.user.name,
    last_name: orderData["user-last_name"] || extra_info.user.last_name,
  };

  return function (dispatch) {
    return fetchPostServerPaymanetezClickToPayStatus(response, orderId)
      .then(response => response.json())
      .then((orderData) => {
        dispatch(changeServerData(orderData))
        return dispatch(push(INFO_RESPONSE_PAYMENT))
      })
      .catch(error => {
        console.error(error);
        return dispatch(changeServerData(error))
      });
  };
}

export const fetchGetServerData = (Url) => {
  return fetch(`${Url}`, {
    method: "GET",
    headers: new Headers({
      'Auth-Token': getAuth(),
      'Content-Type': 'application/json'
    }),
    credentials: "same-origin"
  })
}

export const asyncFetchGetServerData = (Url) => {
  return function (dispatch) {
    return fetchGetServerData(Url)
      .then(response => response.json())
      .then((orderData) => {
        orderData && orderData.data && orderData.data.application ? (() => {
          dispatch(changeCurrentTheme(orderData.data.application))
          delete orderData.data.application;
        })() : false;

        return dispatch(changeServerData(orderData))
      }
      ).catch(error => {
        console.error(error);
        return dispatch(changeServerData(error))
      });
  };
}

export const fetchOtherPaymentStatus = (url, data) => {
  return fetch(`${url}`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: new Headers({
      'Auth-Token': getAuth(),
      'Content-Type': 'application/json'
    }),
    credentials: "same-origin"
  })
}

export const asyncfetchOtherPaymentStatus = (url, data) => {
  return function (dispatch) {
    return fetchOtherPaymentStatus(url, data)
      .then(response => response.json())
      .then((orderData) => {
        dispatch(changeServerData(orderData))
        return dispatch(push(INFO_RESPONSE_PAYMENT))
      }
      ).catch(error => {
        console.error(error);
        return dispatch(changeServerData(error))
      });
  };
}

export const asyncfetchRappiPaymentStatus = (url, data) => {
  return function (dispatch) {
    return fetchOtherPaymentStatus(url, data)
      .then(response => response.json())
      .then((orderData) => {
        dispatch(changeServerData(orderData))
      }
      ).catch(error => {
        console.error(error);
        return dispatch(changeServerData(error))
      });
  };
}

// Servicio para envio de OTP en ewallet (daviplata)
export const fetchTransactionVerify = (url, data) => {
  return fetch(`${url}`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: new Headers({
      'Auth-Token': getAuth(),
      'Content-Type': 'application/json'
    }),
    credentials: "same-origin"
  })
}

export const asyncfetchTransactionVerify = (url, data) => {
  return function (dispatch) {
    return fetchTransactionVerify(url, data)
      .then(response =>
        response.json()
      )
      .then((orderData) => {
        dispatch(changeServerData(orderData))
        return dispatch(push(INFO_RESPONSE_PAYMENT))
      }
      ).catch(error => {
        console.error(error);
        return dispatch(changeServerData(error))
      });
  };
}

export const fetchTransactionRetry = (url) => {
  return fetch(`${url}`, {
    method: "POST",
    headers: new Headers({
      'Auth-Token': getAuth(),
      'Content-Type': 'application/json'
    }),
    credentials: "same-origin"
  })
    .then(response => response.json())
    .then(responseData => {
      if (responseData.data.url_return) {
        window.location = responseData.data.url_return;
      }
    } )
}
